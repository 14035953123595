
// import { ref } from 'vue';
export default {
    props: {
        addOne: Function
    },
    // setup() {


    // }
}



import { ref } from 'vue'
export default {


    setup() {
        const image = ref(null)
        const imageUrl = ref(null)
        const avatar = ref(null)


        function handleInput(e) {
            let inputField = e.target;
            if (inputField.value.length >= 1) {
                let nextField = inputField.nextElementSibling;
                return nextField && nextField.focus();
            }
        }
        function emitEvent(event) {
            var reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.onload = () => {
                const imagee = {
                    name: event.target.files[0].name,
                    size: event.target.files[0].size,
                    lastModifiedDate: event.target.files[0].lastModifiedDate,
                    base64: reader.result
                }
                image.value = imagee
                const file = event.target.files[0]
                imageUrl.value = URL.createObjectURL(file)
                avatar.value = imageUrl.value
            }
        }

        // function handleImageInput(event) {
        //     let reader = new FileReader()
        //     image.value = event.target.files[0].name
        //     console.log(image.value)
        //     reader.onload = () => {
        //         image.value = event.target.files[0].name
        //         console.log(image.value)
        //     }
        // }
        return {
            handleInput, avatar, emitEvent
        }
    },


    props: {
        addOne: Function
    },
}

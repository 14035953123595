


export default {

    setup() {
        function handleInput(e) {
            let inputField = e.target;
            if (inputField.value.length >= 1) {
                let nextField = inputField.nextElementSibling;
                return nextField && nextField.focus();
            } else {
                let prevField = inputField.previousElementSibling;
                return prevField && prevField.focus();
            }

        }
        return {
            handleInput
        }
    },

    props: {
        addOne: Function
    },
}


import { ref } from 'vue';
import first from './signup-socials.vue'
import second from './signup-phonenumber.vue'
import third from './signup-phoneverification.vue'
import fourth from './signup-form.vue'
import fifth from './pending.vue'
export default {

    setup() {


        const counter = ref(0)
        const selectedComponent = ref('first')
        function addOne() {
            counter.value++
        }
        return { counter, addOne, selectedComponent: selectedComponent.value };
    },
    components: {
        first,
        second,
        third,
        fourth,
        fifth,

    }
};

